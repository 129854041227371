import React from 'react';
import * as FormStyling from "../Form.module.scss";

// The ID of the Lead field in Salesforce
const IS_AVAILABLE_INPUT_ID = '00N6900000LFZz8'

const SelectOption = ({
  label,
  name,
  isRequired,
  customOptions,
  errors,
  register,
  setFormValue,
  premadeOptions,
}) => {
  const [hasRegistered, setHasRegistered] = React.useState(false)
  if (customOptions.length === 0 && premadeOptions) {
    const { options }  = JSON.parse(premadeOptions[Object.keys(premadeOptions)[0]])
    const shouldRegisterAvailability = name === 'country_code'
    const getIsAvailable = (code) => {
      // Set country to available if options haven't finished loading to prevent false if using autofill.
      if (!options || !code) return true
      return !!options.find((option) => option.code === code)?.isAvailable
    }
    if (shouldRegisterAvailability && !hasRegistered) {
      // Used in Salesforce to disqualify leads in unsupported markets
      register(IS_AVAILABLE_INPUT_ID)
      // Default to true to prevent faulty disqualifying leadss
      setFormValue(IS_AVAILABLE_INPUT_ID, true)
      setHasRegistered(true)
    }
    return (
      <div className="field is-valid">
        <label className="field-input">
          <select
            {...register(name, { 
              required: isRequired, 
              onChange: (event) => {
                if (shouldRegisterAvailability) {
                  setFormValue(IS_AVAILABLE_INPUT_ID, getIsAvailable(event.target.value))
                }
              }
            })}
            className={
              errors[name]
                ? `${FormStyling.formSelect} ${FormStyling.invalid}`
                : `${FormStyling.formSelect}`
            }
          >
            <option value=""> </option>
            {options?.map((option) => {
              return (
                <option key={option.name} value={option.code}>
                  {option.name}
                </option>
              );
            })}
          </select>
          <label className={`field-label ${FormStyling.fieldSelectLabel} `}>
            {label}
            {isRequired && <span className="primary-color"> *</span>}
          </label>
        </label>
      </div>
    );
  } else {
    return (
      <div className="field is-valid">
        <label className="field-input">
          <select
            {...register(label, { required: isRequired })}
            className={
              errors[label]
                ? `${FormStyling.formSelect} ${FormStyling.invalid}`
                : `${FormStyling.formSelect}`
            }
          >
            <option value=""> </option>
            {customOptions.map((option) => {
              return (
                <option key={option.id} value={option.label}>
                  {option.label}
                </option>
              );
            })}
          </select>
          <label className={`field-label ${FormStyling.fieldSelectLabel}`}>
            {label}
            {isRequired && <span className="primary-color"> *</span>}
          </label>
        </label>
      </div>
    );
  }
};

export default SelectOption;
